import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "../components/UI/Input"; // Custom input component
import Button from "../components/UI/Button"; // Custom button component
import { changePassword } from "../utils/auth"; // API function
import { useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import { useSnackBar } from "../store/context/snackbar-context"; // Snackbar context
import Breadcrumb from "../components/ Breadcrumb/ Breadcrumb";
import { useTranslationsContext } from "../store/context/translations-context";

const ChangePassword = () => {
  const [fieldValues, setFieldValues] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [buttonBottom, setButtonBottom] = useState("1rem");
  const [isTranslating, setIsTranslating] = useState(true); // State to handle translation loading
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { notify } = useSnackBar(); // Get the notify function from the snackbar context

  const style = isMobile ? { paddingLeft: 0 } : {};

  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  // State to store translated labels
  const [translatedLabels, setTranslatedLabels] = useState({
    home: '',
    account: '',
    changePassword: '',
    currentPassword: '',
    newPassword: '',
    save: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const home = await requestTranslation('home');
      const account = await requestTranslation('account');
      const changePassword = await requestTranslation('change-password');
      const currentPassword = await requestTranslation('current-password');
      const newPassword = await requestTranslation('new-password');
      const save = await requestTranslation('save');

      setTranslatedLabels({
        home,
        account,
        changePassword,
        currentPassword,
        newPassword,
        save
      });

      setIsTranslating(false); // Set translation loading to false after translations are fetched
    };

    translateLabels();
  }, [requestTranslation]);

  // Breadcrumb data using translated labels
  const breadcrumbData = {
    data: [
      { label: translatedLabels.home, url: "/" },
      { label: translatedLabels.account, url: "/account" },
      { label: translatedLabels.changePassword, url: "/account/change-password" },
    ],
  };

  // Adjust button position based on the footer
  useEffect(() => {
    const adjustButtonPosition = () => {
      const footerElement = document.querySelector("footer");
      if (!footerElement) return;

      const footerRect = footerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (footerRect.top < viewportHeight) {
        const overlap = viewportHeight - footerRect.top;
        setButtonBottom(overlap + "px");
      } else {
        setButtonBottom("1rem");
      }
    };

    window.addEventListener("scroll", adjustButtonPosition);
    window.addEventListener("resize", adjustButtonPosition);
    adjustButtonPosition();

    return () => {
      window.removeEventListener("scroll", adjustButtonPosition);
      window.removeEventListener("resize", adjustButtonPosition);
    };
  }, []);

  // Handle field value changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { currentPassword, newPassword } = fieldValues;

    try {
      // Call the changePassword API
      await changePassword(currentPassword, newPassword);

      // Notify the user of success
      notify("Password changed successfully!", "success");
    } catch (error) {
      // Notify the user of error
      notify("Failed to change password. Please try again.", "error");
    }
  };

  // Show a loading spinner while translations are being fetched
  if (isTranslating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumb data={breadcrumbData.data} />
      <form className="w-full p-6 bg-white" onSubmit={handleSubmit}>
        {/* Change Password Section */}
        <Box className="w-full" sx={{ mb: 3 }}>
          <h3 className="mb-6">{translatedLabels.changePassword}</h3>
          <Grid container spacing={2}>
            {/* Current Password Field */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.currentPassword}
                  name="currentPassword"
                  value={fieldValues.currentPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            {/* New Password Field */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.newPassword}
                  name="newPassword"
                  value={fieldValues.newPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Fixed Button */}
        <Grid container xs={12} sm={1}>
          <div
            className={
              isMobile
                ? "w-full fixed bottom-0 left-0 p-6"
                : "w-full fixed bottom-0 left-0 p-6"
            }
            style={{
              bottom: buttonBottom,
              transition: "bottom 0.2s ease",
              zIndex: 9999, // Ensure the button stays on top
            }}
          >
            <Box className="flex justify-center" style={style}>
              <Button
                className={isMobile ? "w-full" : "w-64"}
                type="submit"
                buttonText={translatedLabels.save}
                fullWidth={isMobile}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "2.5rem",
                  fontWeight: 700,
                  color: "#fff", // Ensure the text is visible
                }}
              />
            </Box>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default ChangePassword;
