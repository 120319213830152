import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "../components/UI/Input"; // Custom input component
import Button from "../components/UI/Button"; // Custom button component
import { useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import { useSnackBar } from "../store/context/snackbar-context"; // Snackbar context
import Breadcrumb from "../components/ Breadcrumb/ Breadcrumb";
import { useTranslationsContext } from "../store/context/translations-context";

const Financials = () => {
  const [fieldValues, setFieldValues] = useState({
    financialPassword: "",
  });
  const [buttonBottom, setButtonBottom] = useState("1rem");
  const [isTranslating, setIsTranslating] = useState(true); // State to handle translation loading
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { notify } = useSnackBar(); // Get the notify function from the snackbar context

  const style = isMobile ? { paddingLeft: 0 } : {};

  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  // State to store translated labels
  const [translatedLabels, setTranslatedLabels] = useState({
    home: '',
    profile: '',
    financials: '',
    financialPassword: '',
    save: '',
    instructions: '',
    disclaimer: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const home = await requestTranslation('home');
      const profile = await requestTranslation('profile');
      const financials = await requestTranslation('financials');
      const financialPassword = await requestTranslation('financial-password');
      const save = await requestTranslation('save');
      const instructions = await requestTranslation('financial-instructions');
      const disclaimer = await requestTranslation('financial-disclaimer');

      setTranslatedLabels({
        home,
        profile,
        financials,
        financialPassword,
        save,
        instructions,
        disclaimer
      });

      setIsTranslating(false); // Set translation loading to false after translations are fetched
    };

    translateLabels();
  }, [requestTranslation]);

  // Breadcrumb data using translated labels
  const breadcrumbData = {
    data: [
      { label: translatedLabels.home, url: "/" },
      { label: translatedLabels.profile, url: "/profile" },
      { label: translatedLabels.financials, url: "/profile/financials" },
    ],
  };

  // Adjust button position based on the footer
  useEffect(() => {
    const adjustButtonPosition = () => {
      const footerElement = document.querySelector("footer");
      if (!footerElement) return;

      const footerRect = footerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (footerRect.top < viewportHeight) {
        const overlap = viewportHeight - footerRect.top;
        setButtonBottom(overlap + "px");
      } else {
        setButtonBottom("1rem");
      }
    };

    window.addEventListener("scroll", adjustButtonPosition);
    window.addEventListener("resize", adjustButtonPosition);
    adjustButtonPosition();

    return () => {
      window.removeEventListener("scroll", adjustButtonPosition);
      window.removeEventListener("resize", adjustButtonPosition);
    };
  }, []);

  // Handle field value changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { financialPassword } = fieldValues;

    try {
      // Here you can add your API logic for the financial password
      console.log("Financial password submitted:", financialPassword);

      // Notify the user of success
      notify("Financial password updated successfully!", "success");
    } catch (error) {
      // Notify the user of error
      notify("Failed to update financial password. Please try again.", "error");
    }
  };

  // Show a loading spinner while translations are being fetched
  if (isTranslating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumb data={breadcrumbData.data} />
      <form className="w-full p-6 bg-white" onSubmit={handleSubmit}>
        {/* Financial Section */}
        <Box className="w-full" sx={{ mb: 3 }}>
          <h3 className="mb-6">{translatedLabels.financials}</h3>

          {/* Instructions Section */}
          <p style={{ marginBottom: "1.5rem", fontSize: "1rem", color: "#333" }}>
            {translatedLabels.instructions}{" "}
            <span style={{ color: "#d32f2f", fontWeight: "bold" }}>
              {translatedLabels.disclaimer}
            </span>
          </p>

          <Grid container spacing={2}>
            {/* Financial Password Field */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.financialPassword}
                  name="financialPassword"
                  value={fieldValues.financialPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {/* Fixed Button */}
        <Grid container xs={12} sm={1}>
          <div
            className={
              isMobile
                ? "w-full fixed bottom-0 left-0 p-6"
                : "w-full fixed bottom-0 left-0 p-6"
            }
            style={{
              bottom: buttonBottom,
              transition: "bottom 0.2s ease",
              zIndex: 9999, // Ensure the button stays on top
            }}
          >
            <Box className="flex justify-center" style={style}>
              <Button
                className={isMobile ? "w-full" : "w-64"}
                type="submit"
                buttonText={translatedLabels.save}
                fullWidth={isMobile}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "2.5rem",
                  fontWeight: 700,
                  color: "#fff", // Ensure the text is visible
                }}
              />
            </Box>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default Financials;
