import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import P4lIcon from "../UI/P4lIcon";
import Input from "../UI/Input";
import Cookies from "js-cookie";
import { fetchCompanyDetails } from "../../utils/cms";
import { FormControl } from "@mui/material";
import { useTranslationsContext } from "../../store/context/translations-context";

function SocialAuth({ social }) {
  const [socialAuthOptions, setSocialAuthOptions] = useState([]);
  const [fieldValues, setFieldValues] = useState('');
  const language = localStorage.getItem('language') || 'pt';

  // Use the TranslationContext to fetch cached translations
  const { requestTranslation } = useTranslationsContext();

  const [translatedText, setTranslatedText] = useState({
    transSelectLabel: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const transSelectLabel = await requestTranslation('select-language');
      setTranslatedText({ transSelectLabel });
    };

    translateLabels();
  }, [requestTranslation]);

  useEffect(() => {
    if (language) {
      setFieldValues(language);
    }
  }, [language]);

  function handleLanguageChange(e) {
    localStorage.setItem('language', e.target.value);
    window.location.reload();
    setFieldValues(e.target.value);
  }

  useEffect(() => {
    const companyName = Cookies.get('brand') || 'planning4life';

    const fetchCompanyData = async () => {
      const companyDetails = await fetchCompanyDetails(companyName);
      if (companyDetails && companyDetails.data && companyDetails.data['social-auth']) {
        setSocialAuthOptions(companyDetails.data['social-auth'].filter(option => option.active === 1));
      }
    };

    fetchCompanyData();
  }, []);

  return (
    <React.Fragment>
      <Box>
        <div className="flex flex-col justify-start gap-8 items-start">
          <div>
            {social !== 'disable' && socialAuthOptions.map((option, index) => (
              <P4lIcon
                key={index}
                href={option.url}
                disableRipple={true}
                icon={option.icon}
                className="text-white"
              />
            ))}
          </div>

          <FormControl className="w-64">
            <Input
              type={'select'}
              label={translatedText.transSelectLabel}
              onChange={handleLanguageChange}
              name={'select-lang'}
              value={fieldValues}
              color={"primary.light"}
              className="text-white"
              icon={`p4l-${fieldValues}`}
              selectObject={{
                app_name: "core",
                model_name: "Language",
                field_key: "alias"
              }}
            />
          </FormControl>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default SocialAuth;
