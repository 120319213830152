import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { LinearProgress } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import PlatFormLogo from '../../UI/PlatFormLogo';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AuthContext from '../../../store/context/auth-context__';
import { useSnackBar } from '../../../store/context/snackbar-context';
import Modal from '../../UI/Modal';
import FinanceStatus from '../../FinanceStatus/FinanceStatus';
import { useTranslationsContext } from '../../../store/context/translations-context';
import Notifications from './Notifications';
import ProfileOptions from './ProfileOptions';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerwidth }) => ({
  position: 'fixed',
  top: 0,
  zIndex: !open ? theme.zIndex.drawer + 1 : theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
  }),
  ...(open && {
    marginLeft: `${drawerwidth}px`,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

function TopNav(props) {
  const authCtx = useContext(AuthContext);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [translatedText, setTranslatedText] = useState({
    profileOptions: '',
    logoutSuccess: '',
  });
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { notify } = useSnackBar();

  // Fetch translations for "Profile Options" and "Logout Success"
  useEffect(() => {
    const translateLabels = async () => {
      const profileOptions = await requestTranslation('profile-options'); // Translate "Opções de Perfil"
      const logoutSuccess = await requestTranslation('logout-success'); // Translate "Logout Success"
      setTranslatedText({
        profileOptions,
        logoutSuccess,
      });
    };
    translateLabels();
  }, [requestTranslation]);

  const handleOpenUserMenu = useCallback((event) => setAnchorElUser(event.currentTarget), []);
  const handleCloseUserMenu = useCallback(() => setAnchorElUser(null), []);
  const handleModalOpen = useCallback(() => setOpenModal(true), []);
  const handleModalClose = useCallback(() => setOpenModal(false), []);

  const handleLogout = useCallback(async () => {
    authCtx.logoutHandler();
    setOpenModal(false);
    setAnchorElUser(null);
    notify(translatedText.logoutSuccess, 'info');
  }, [authCtx, translatedText.logoutSuccess, notify]);

  // Memoize props for Modal to prevent re-renders caused by new object references
  const modalProps = useMemo(() => ({
    title: 'exit',
    message: 'confirm-exit',
    open: openModal,
    onClose: handleModalClose,
    action: handleLogout,
  }), [openModal, handleModalClose, handleLogout]);

  return (
    <React.Fragment>
      <AppBar
        style={{ backgroundColor: props.backgroundColor }}
        position="fixed"
        open={props.open}
        drawerwidth={props.drawerWidth}
        elevation={0}
        className='h-auto top-0'
      >
        <Toolbar className='flex justify-between'>
          <Box className='flex gap-2 sm:gap-6 '>
            <IconButton
              style={{ color: props.color }}
              edge="start"
              aria-label="open drawer"
              onClick={props.sideBarHandler}
              sx={{ ...(props.open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box className="flex gap-8">
              <Link className='flex items-center' to=".">
                <PlatFormLogo type={isMobile ? "entrance" : "small"} className={`max-h-8 ${props.open && 'hidden sm:block'} `} />
              </Link>
              <Box className="flex gap-8 sm:gap-16 items-center">
                <FinanceStatus status={props.topNavItems.official_status} />
              </Box>
            </Box>
          </Box>
          <Box className="flex items-center gap-2 sm:gap-6">
            <Notifications />
            <Tooltip title={translatedText.profileOptions}> {/* Translated "Opções de Perfil" */}
              <IconButton onClick={handleOpenUserMenu} size='medium'>
                {authCtx.photo && authCtx.photo !== 'null' ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}${authCtx.photo}`}
                    alt="User profile"
                    style={{ width: 28, height: 28, borderRadius: '100%' }} // Adjust size as needed
                  />
                ) : (
                  <span className={`p4l-person-line w-fit`}></span>
                )}
              </IconButton>
            </Tooltip>
            <ProfileOptions
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              menuItems={props.topNavItems.items}
              profiles={props.topNavItems.profiles}
              onOpen={handleModalOpen}
            />
          </Box>
        </Toolbar>
      </AppBar>
      {openModal && <Modal {...modalProps} />}
    </React.Fragment>
  );
}

export default React.memo(TopNav);
