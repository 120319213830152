import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import CardButton from '../components/UI/CardButton';
import { useTranslationsContext } from '../store/context/translations-context';

function Account(props) {
    // Access TranslationContext for fetching translations
    const { requestTranslation } = useTranslationsContext();

    // State to store translated labels
    const [translatedLabels, setTranslatedLabels] = useState({
        editData: '',
        changePassword: '',
        authorizations: ''
    });

    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const translateLabels = async () => {
            const editData = await requestTranslation('edit-data');
            const changePassword = await requestTranslation('change-password');
            const authorizations = await requestTranslation('authorizations');

            setTranslatedLabels({
                editData,
                changePassword,
                authorizations
            });

            setIsLoading(false); // Set loading to false after translations are fetched
        };

        translateLabels();
    }, [requestTranslation]);

    // Show a loading spinner while translations are being fetched
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box>
            <Box id="cards-grid" className="grid grid-cols-2 gap-4 justify-center p-16 w-full sm:w-2/3 xl:w-1/2 mx-auto">
                <CardButton link="my-account" iconClass="p4l p4l-profile text-blue-800" title={translatedLabels.editData} />
                <CardButton link="change-password" iconClass="p4l p4l-security text-green-500" title={translatedLabels.changePassword} />
                <CardButton link="authorizations" iconClass="p4l p4l-safe-box text-pink-400" title={translatedLabels.authorizations} />
            </Box>
        </Box>
    );
}

export default Account;
