import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCSRFToken } from '../utils/auth';

function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/', { replace: true });
      return;
    }

    const confirmEmail = async () => {
      try {
        const csrfToken = getCSRFToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}_allauth/browser/v1/auth/email/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ key: token }),
          credentials: 'include'
        });

        if (response.ok) {
          localStorage.removeItem("email");
          navigate('/notification', {
            state: {
              messages:{
              title: 'email-confirmed',
              success: 'email-confirmed-success',
              instructions: "email-confirmed-instructions",
              },
              buttons: [{
                text: "continue-access",
                link: "/",
              }],
            }
          });
        } else {
          navigate('/notification', {
            state: {
              messages:{
              title: 'email-confirmed-invalid-link',
              success: 'email-confirm-link-expired-or-invalid',
              instructions: "email-confirm-link-expired-instructions",
              },
              buttons:[{
                text: "continue-access-limited",
                link: "/",
              }],
            }
          });
        }
      } catch (error) {
        console.error('Failed to confirm email:', error);
        navigate('/notification', {
          state: {
            messages:{
            title: 'email-confirmed-invalid-link',
            success: 'email-confirm-link-expired-or-invalid',
            instructions: "email-confirm-link-expired-instructions",
            },
            buttons:[{
              text: "continue-access-limited",
              link: "/",
            }],
          }
        });
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return null;
}

export default VerifyEmail;
