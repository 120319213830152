import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslationsContext } from '../../../store/context/translations-context';

// Define a mapping of alert levels to their corresponding styles
const alertStyles = {
  info: {
    textColor: 'text-blue-800',
    bgColor: 'bg-blue-50',
  },
  danger: {
    textColor: 'text-red-800',
    bgColor: 'bg-red-50',
  },
  success: {
    textColor: 'text-green-800',
    bgColor: 'bg-green-50',
  },
  warning: {
    textColor: 'text-yellow-800',
    bgColor: 'bg-yellow-50',
  },
  dark: {
    textColor: 'text-gray-800',
    bgColor: 'bg-gray-50',
  },
};

function AlertMessage({ message, action, level }) {
  const alertStyle = alertStyles[level] || alertStyles.info; // Default to "info" if no level is provided

  // Use TranslationContext to fetch translations
  const { requestTranslation } = useTranslationsContext();

  const [takeActionText, setTakeActionText] = useState('');

  useEffect(() => {
    const translateText = async () => {
      const translatedText = await requestTranslation('take-action');
      setTakeActionText(translatedText);
    };

    translateText();
  }, [requestTranslation]);

  return (
    <div
      className={`p-4 text-sm ${alertStyle.textColor} rounded-lg ${alertStyle.bgColor}`}
      role="alert"
    >
      <svg
        className="flex-shrink-0 inline w-4 h-4 mr-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>

      <span>{message}</span>
      {action && (
        <Link to={action} className="ml-2 text-blue-600 underline">
          {takeActionText}
        </Link>
      )}
    </div>
  );
}

export default AlertMessage;
