import React, { useState, useEffect, useContext } from "react";
import { Divider, Typography } from "@mui/material";
import { Link, useNavigate, useActionData, json } from "react-router-dom";
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import AuthForm from "../components/Auth/AuthForm";
import LinkMui from '@mui/material/Link';
import AuthContext from "../store/context/auth-context__";
import { useTranslationsContext } from "../store/context/translations-context";

function Register() {
  const actionData = useActionData();
  const authCtx = useContext(AuthContext);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const navigate = useNavigate();

  const [translatedTexts, setTranslatedTexts] = useState({
    alreadyHaveAccount: '',
    loginHere: ''
  });

  useEffect(() => {
    const loadTranslations = async () => {
      const alreadyHaveAccount = await requestTranslation('register-already-have-account');
      const loginHere = await requestTranslation('register-login-here');
      setTranslatedTexts({ alreadyHaveAccount, loginHere });
    };

    loadTranslations();
  }, [requestTranslation]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'appauth/get-csrf-token/', {
        method: 'GET',
        credentials: 'include',  // Ensure cookies are included in the request
      });
      const result = await response.json();
      localStorage.setItem('csrfToken', result.csrf_token);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    authCtx.refetch();
    if (authCtx.isLoggedIn) {
      navigate("/");
    }
  }, [authCtx.isLoggedIn, navigate]);

  function submitHandler() {
    navigate("/login");
  }

  useEffect(() => {
    if (actionData && actionData.ok) {
      navigate("/notification", {
        state: {
          messages: {
            title: "account-created",
            success: 'account-created-access-limited',
            instructions: "account-created-confirm-email-instructions",
          },
          buttons: [
            {
              text: "continue-access-limited",
              link: "/",
            },
            {
              text: "resend-email",
              link: "/resend-email",
            },
          ]
        },
      });
    }
  }, [actionData, navigate]);

  return (
    <>
      <AuthForm>
        <FormLayout submitHandler={submitHandler} formType='router' colSpan={12} className="flex flex-col items-center gap-8" />
        <Typography className="flex text-white gap-2" variant="body2">
          {translatedTexts.alreadyHaveAccount}
          <Link to="/login">
            <LinkMui sx={{ color: 'primary.accent', textDecoration: 'underline' }}>
              {translatedTexts.loginHere}
            </LinkMui>
          </Link>
        </Typography>
      </AuthForm>
    </>
  );
}

export default Register;

export async function action({ request }) {
  const { REACT_APP_API_URL } = process.env;
  const data = await request.formData();

  const authData = {
    email: data.get('email'),
    password: data.get('password'),
    confirm_password: data.get('confirm_password'),
    username: data.get('username'),
  };

  localStorage.setItem("email", authData.email);

  if (authData.password !== authData.confirm_password) {
    return { ok: false, confirm_password: ['Passwords don\'t match'] };
  }

  const response = await fetch(REACT_APP_API_URL + '_allauth/browser/v1/auth/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem("csrfToken"),
    },
    body: JSON.stringify(authData),
    credentials: 'include',
  });

  if (response.status === 400) {
    const errorData = await response.json();
    return { ok: false, status: response.status, data: errorData };
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }

  await response.json();
  return { ok: true, response: response };
}
