import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, CircularProgress } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import CardButton from '../components/UI/CardButton';
import { useTranslationsContext } from '../store/context/translations-context';

function Profile(props) {
    const loaderData = useLoaderData();
    const { name, job, civil_state, photo } = loaderData;
    const [isLoading, setIsLoading] = useState(true);

    // Use the TranslationContext to fetch cached translations
    const { requestTranslation } = useTranslationsContext();

    // State to store translated labels
    const [translatedLabels, setTranslatedLabels] = useState({
        insurance: '',
        credits: '',
        income: '',
        editProfile: '',
        myAccount: '',
        family: '',
        invite: '',
        patrimony: '',
        financialHealth: '',
        help: '',
        myDocuments: '',
        financials:''
    });

    useEffect(() => {
        const translateLabels = async () => {
            // Request all translations
            const insurance = await requestTranslation('insurance');
            const credits = await requestTranslation('credits');
            const income = await requestTranslation('income');
            const editProfile = await requestTranslation('edit-profile');
            const myAccount = await requestTranslation('my-account');
            const family = await requestTranslation('family');
            const invite = await requestTranslation('invite');
            const patrimony = await requestTranslation('patrimony');
            const financialHealth = await requestTranslation('financial-health');
            const help = await requestTranslation('help');
            const myDocuments = await requestTranslation('my-documents');
            const financials = await requestTranslation('financials');

            // Set the translations in state
            setTranslatedLabels({
                insurance,
                credits,
                income,
                editProfile,
                myAccount,
                family,
                invite,
                patrimony,
                financialHealth,
                help,
                myDocuments,
                financials
            });

            // Set loading to false after translations are fetched
            setIsLoading(false);
        };

        // Set loading to true before starting translations

        translateLabels();
    }, [requestTranslation]);

    // Show a loading spinner while translations are being fetched
    if (isLoading) {
        return             <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
    </Box>;
    }

    return (
        <Box>
            {/* Profile Header */}
            <Box style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="p-8 text-black flex flex-col items-center">
                {/* Profile Picture and Rating */}
                <Box className="relative flex flex-col items-center">
                    <Avatar
                        src={`${process.env.REACT_APP_API_URL}${photo}`}
                        alt="Profile Picture"
                        sx={{ width: 100, height: 100 }}
                    />
                    <Box className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 bg-pink-500 text-white py-1 px-2 rounded-full">
                        <Typography variant="caption">4.5</Typography>
                    </Box>
                </Box>

                {/* Name and Info */}
                <Typography variant="h6" className="mt-4 font-bold text-black">
                    {name}
                </Typography>
                <Typography variant="body2" className="mt-2 text-gray-600">
                    {civil_state} {job}
                </Typography>

                {/* Stats */}
                <Box className="flex justify-around w-full mt-8">
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            5
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            {translatedLabels.insurance}
                        </Typography>
                    </Box>
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            5
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            {translatedLabels.credits}
                        </Typography>
                    </Box>
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            1
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            {translatedLabels.income}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Cards Grid */}
            <Box id="cards-grid" className="grid grid-cols-2 gap-4 justify-center p-16 w-full sm:w-2/3 xl:w-1/2 mx-auto">
                <CardButton link="/profile/my-profile" iconClass="p4l p4l-profile text-blue-800" title={translatedLabels.editProfile} />
                <CardButton link="/account" iconClass="p4l p4l-golden-bars text-green-500" title={translatedLabels.myAccount} />
                <CardButton link="/profile/financials" iconClass="p4l p4l-business-investment text-red-500" title={translatedLabels.financials} />
                <CardButton iconClass="p4l p4l-family-dependents text-pink-400" title={translatedLabels.family} />
                <CardButton iconClass="p4l p4l-email text-black" title={translatedLabels.invite} />
                <CardButton link="/pages/patrimony" iconClass="p4l p4l-calculator2 text-blue-500" title={translatedLabels.patrimony} />
                <CardButton link="/pages/financial-health" iconClass="p4l p4l-health text-green-500" title={translatedLabels.financialHealth} />
                <CardButton iconClass="p4l p4l-support text-orange-500" title={translatedLabels.help} />
                <CardButton link="/pages/account_my-documents" iconClass="p4l p4l-documents text-green-500" title={translatedLabels.myDocuments} />
            </Box>
        </Box>
    );
}

export default Profile;
