import React, { useState, useContext, useEffect } from 'react';
import Modal from '../components/UI/Modal';
import Button from '../components/UI/Button';
import AuthContext from '../store/context/auth-context__';
import { disableUser } from '../utils/auth';
import Breadcrumb from '../components/ Breadcrumb/ Breadcrumb';
import { Box, CircularProgress } from '@mui/material';
import { useTranslationsContext } from '../store/context/translations-context';

export default function Authorizations() {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTranslating, setIsTranslating] = useState(true); // Loading state for translations
  const authCtx = useContext(AuthContext);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  // State to store translated labels
  const [translatedLabels, setTranslatedLabels] = useState({
    home: '',
    account: '',
    authorizations: '',
    deleteAccount: '',
    deleteAccountTitle: '',
    deleteAccountMessage: '',
    confirmDelete: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const home = await requestTranslation('home');
      const account = await requestTranslation('account');
      const authorizations = await requestTranslation('authorizations');
      const deleteAccount = await requestTranslation('delete-account');
      const deleteAccountTitle = await requestTranslation('delete-account-title');
      const deleteAccountMessage = await requestTranslation('delete-account-message');
      const confirmDelete = await requestTranslation('confirm-delete');

      setTranslatedLabels({
        home,
        account,
        authorizations,
        deleteAccount,
        deleteAccountTitle,
        deleteAccountMessage,
        confirmDelete
      });

      setIsTranslating(false); // Translations are done
    };

    setIsTranslating(true); // Set loading state before fetching translations
    translateLabels();
  }, [requestTranslation]);

  // Show a loading spinner while translations are being fetched
  if (isTranslating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  // Breadcrumb data using translated labels
  const breadcrumbData = {
    data: [
      { label: translatedLabels.home, url: "/" },
      { label: translatedLabels.account, url: "/account" },
      { label: translatedLabels.authorizations, url: "/account/authorizations" },
    ],
  };

  const handleDeleteClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    await disableUser();  // Call API to disable user account
    setIsLoading(false);
    setOpenModal(false);
    authCtx.logoutHandler();  // Log out user after disabling account
  };

  return (
    <div>
      {/* Breadcrumb Component */}
      <Breadcrumb data={breadcrumbData.data} />
      <Box className='w-full p-2 mt-6'>
        {/* Custom Red Button */}
        <Button
          variant="contained"
          color="error"
          buttonText={translatedLabels.deleteAccount}
          onClick={handleDeleteClick}
          style={{ backgroundColor: 'red', color: 'white' }}
          isLoading={isLoading} // Assuming your custom Button component supports this prop
        />
      </Box>

      {/* Modal for confirmation */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        title={translatedLabels.deleteAccountTitle}
        message={translatedLabels.deleteAccountMessage}
        action={handleConfirmDelete}
        confirmText={translatedLabels.confirmDelete}
      />
    </div>
  );
}
