import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import MetaData from '../components/UI/MetaData';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import { Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslationsContext } from '../store/context/translations-context';

function Home() {
  const loaderData = useLoaderData();
  const meta = loaderData?.meta || "";
  const theme = useTheme();

  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  const [translatedLabels, setTranslatedLabels] = useState({
    seguros: '',
    creditos: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const seguros = await requestTranslation('insurance');
      const creditos = await requestTranslation('credits');

      setTranslatedLabels({
        seguros,
        creditos
      });
    };

    translateLabels();
  }, [requestTranslation]);

  return (
    <React.Fragment>
      <MetaData data={JSON.stringify(meta)} />
      <div className='flex flex-col gap-16'>
        <Box display="flex" justifyContent="center" gap={1}>
          <Chip
            sx={{
              fontSize: '1rem',
              padding: '0.5rem',
            }}
            clickable
            label={translatedLabels.seguros}
            size="medium"
            variant="outlined"
          />
          <Chip
            sx={{
              fontSize: '1rem',
              padding: '0.5rem',
            }}
            clickable
            label={translatedLabels.creditos}
            size="medium"
            variant="outlined"
          />
        </Box>
        <Chat />
        <Swiper />
        <ContentPage />
      </div>
    </React.Fragment>
  );
}

export default Home;
