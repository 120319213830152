import React,{useState,useEffect} from "react";

import { Form as FormRouter } from 'react-router-dom';
import Box from "@mui/material/Box";
import Input from "../Input";
import Button from "../Button";
import { getCSRFToken } from "../../../utils/auth";
import { useSnackBar } from "../../../store/context/snackbar-context";

import { useParams } from "react-router-dom";

import {useMediaQuery,useTheme} from "@mui/material";
import {useActionData,useNavigate,useNavigation } from "react-router-dom";

import { useActionDataContext } from "../../../store/context/action-context";
import { FormControl,Grid } from '@mui/material';
import { extractFields } from "../../../utils/cms";

const Form = ({ action , context ,loaderData,formContent,formKey ,submitHandler, className, colSpan,formType,actionDataAlternative,isSubmitting}) => {

  //const actionData = useActionData();
  const routerData = useActionData();
  //console.log(context)
  const { setActionData ,actionData} = useActionDataContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [thereIsAFile, setThereIsAFile] = useState(false);
  const navigate = useNavigate();
  const [fieldValues, setFieldValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation();

  const defaultColumnLayout = formContent?.columnLayout || 1; // Default to 1 column if not specified
  //const actionData = (formType==='basic'  ? actionDataAlternative : rawActionData);
  const { notify } = useSnackBar();

  const params = useParams();
  const id= params.id;
  const [buttonBottom, setButtonBottom] = useState("1rem"); // State to manage button's bottom position
//console.log(loaderData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//console.log(actionData);
//console.log(routerData);


useEffect(() => {
  // Adjust button position based on footer position
  const adjustButtonPosition = () => {
    const footerElement = document.querySelector("footer"); // Search for the footer element
    if (!footerElement) return;

    const footerRect = footerElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    if (footerRect.top < viewportHeight) {
      const overlap = viewportHeight - footerRect.top ;
      setButtonBottom(overlap + "px");
    } else {
      setButtonBottom("1rem");
    }
  };

  window.addEventListener("scroll", adjustButtonPosition);
  window.addEventListener("resize", adjustButtonPosition);
  adjustButtonPosition();

  return () => {
    window.removeEventListener("scroll", adjustButtonPosition);
    window.removeEventListener("resize", adjustButtonPosition);
  };
}, []);


useEffect(() => {

  if(formType!=='router') return;
  if(formType==='router') {
    //console.log(navigation)
  setActionData(routerData);
  }
  /* eslint-disable */
}, [routerData]);

useEffect(() => {

 setFormSubmitted(false);
}, []);


  useEffect(() => {
    const handleSnackbarMessages = async () => {


      if(!actionData || (!formSubmitted)  ) return
      let message;
      if (actionData && actionData.ok ) {

        // Assuming success and error messages are properly set in formContent
        //console.log(actionData);
        //console.log(formType);
        //console.log(context);
        if(actionData && actionData.ok && formType!=='router' && context==='update'){
          setActionData(undefined);
          navigate("");
        }
        message = formContent?.messages?.success?.label || actionData && actionData.message || actionData && actionData.data?.message;

        if(message){
          notify(message,'success');

        }


      } else if (actionData && !actionData.ok ) {
        //console.log(navigation.state)

        // Assuming there is a default error label in formContent.messages.error
        // If you have a specific error message in rawActionData you want to translate,
        // You will similarly use translate function as above.
          message = formContent?.messages?.error?.label || actionData && actionData.data && (actionData.data?.detail || actionData.data?.message) || undefined;
          if (message){
            notify(message,'error');

          }



      }

    };

    handleSnackbarMessages();



  }, [actionData]);

  if (!formContent) return;



  const handleChange = (e, sectionName = '',manualName) => {
    //console.log(e)

    let name,value;

    if (e && !e.target) {
      name= manualName;
      value=e;
    }
    else{
      name=e?.target.name;
      value=e?.target.value;

    }
    //console.log(name);
    const fieldName = sectionName ? `${sectionName}.${name}` : name;
    setFieldValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
    //console.log(fieldName);
  };


  function customHandlers(label,e)

{


if (label==='remember-me'){
    if(e.target.checked)
    localStorage.setItem('refresh',true);
  else
  localStorage.removeItem('refresh');
}

}

console.log(formContent);

  const style = isMobile ? {   paddingLeft: 0 } : {}
console.log(theme.palette.primary["accent"])
  const renderFormContent = () => (
    <>
      <Grid   container  xs={12} sm={colSpan || ''} >
        {renderFields(formContent.fields)}

        <div
          className={
            formContent.buttons[0].position === "fixed"
              ? `${isMobile ? "bg-transparent w-full left-0 bottom-0" : "bg-transparent w-full left-0"} p-6 fixed`
              : "w-full"
          }
          style={{ bottom: buttonBottom,  transition: "bottom 0.2s ease" }} // Adjust the bottom position dynamically
        >
        {formContent && formContent.buttons.map((button, index) => (
          <Box className="flex justify-center" style={style}>
            <Button
            className={isMobile ? "w-full" : "w-64"}
              sx={{  backgroundColor: theme.palette.primary[button.color] || theme.palette.primary.main, // Adjust according to your theme settings
                   borderRadius: '2.5rem', fontWeight: 700  }}
              fullWidth={button.fullWidth}
              variant={button.variant}
              size={button.size}
              type="submit"
              endIcon={   <span className={`${button.endicon}`}></span>}

              isLoading={formType==='router' ? navigation.state==='submitting' || isSubmitting : isLoading }
              buttonText={button.label}
            />
          </Box>
        ))}
        </div>
      </Grid>


      <input name='app_name' type='hidden' value={formContent.object?.app_name} />
      <input name='model_name' type='hidden' value={formContent.object?.model_name} />
      <input name='model_key_id' type='hidden' value={formContent.object?.model_key_id} />
      <input name='detail_id' type='hidden' value={id} />


    </>
  );
  const renderFields = (fields, sectionName = '', columnLayout = defaultColumnLayout) => {

    return fields.map((field, index) => {
      console.log(field.name)
      console.log(loaderData)
      console.log(loaderData[field.name]);
      //console.log(actionData && !actionData.ok &&  actionData[field.name] && actionData[field.name][0])
      if (field.type === 'section') {
        const sectionColumnLayout = field.columnLayout || defaultColumnLayout;
        return (
          <Box className="w-full"   key={index} sx={{ mb: 3 }} style={style}>
            <h3 className='mb-6'>{field.label}</h3>
            <Grid  className="w-full" container columnSpacing={2}>
              {renderFields(field.fields, field.name, sectionColumnLayout)}
            </Grid>
          </Box>
        );
      } else if (loaderData && loaderData[field.name]) {

        return (
          <React.Fragment>

          <Grid className="flex items-center" columnSpacing={2} alignItems={"center"}  justifyContent="center"   item xs={field.halfWidth ? 6 : 12} sm={field.halfWidth ? 6 : 12} lg={field.fullWidth ? 12 : (field.halfWidth ? 6 : 12 / columnLayout)} key={index}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Input
                type={field.type}
                label={(loaderData && loaderData[field.name] && loaderData[field.name].label ? loaderData[field.name].label: '') }
                name={field.name}
                color={field.color}
                placeholder={(!loaderData || loaderData[field.name] ===null ) ? field.placeholder : ''}
                selectObject={field.object}
                selectLabel={field.selectLabel}
                errorMessage={(formSubmitted || formType === 'router') && actionData && !actionData.ok && (
                  (actionData.data && actionData.data[field.name] && actionData.data[field.name][0]) ||
                  (actionData && actionData[field.name] && actionData[field.name][0]) ||
                  (Array.isArray(actionData?.data?.errors) && actionData.data.errors.find(err => err.param === field.name)?.message)
                )}
                disabled={field.edit === false}
                readOnly={field.edit === false}
                to={field?.to && field.to}
                value={fieldValues[field.name] !== undefined ? fieldValues[field.name] : (loaderData && loaderData[field.name] !==null && (loaderData[field.name] ? (loaderData[field.name].value ==='None' && field.type!=='date' ? '' : loaderData[field.name].value) : loaderData[field.name])) || null}
                onChange={field.handlers && field.handlers.onChange ? (e)=>customHandlers(field.handlers.onChange,e) : (e) => handleChange(e, sectionName,field.name)}
              />

            </FormControl>
          </Grid>
          </React.Fragment>
        );
      }
      else return null
    });
  };



  async function submitFormData(formData, action) {
    const { REACT_APP_API_URL } = process.env;
    const token = getCSRFToken();
    const user = localStorage.getItem('user');
    const id = action === "new" ? undefined : formData.get('detail_id');

    let documentWebhookAPI = false;
    let fileName = "";
    let fileType = "document";
    let thereIsAFile = false;

    // Separate FormData for files and non-file data
    let combinedFormData = new FormData();
    let values = {};  // To store field values
    let fields = [];  // To store the list of field objects

    // Keys that should be excluded from the values object
    const excludedKeys = ['photo', 'context', 'token', 'url', 'method', 'model_name', 'app_name', 'model_key_id', 'detail_id'];

    // Extract files and append them to FormData
    formData.forEach((value, key) => {
      if (value instanceof File) {
        thereIsAFile = true;
        combinedFormData.append(key, value);  // Append files directly to FormData
        fields.push({ name: key });  // Add the field metadata
        if (key === 'file') {
          documentWebhookAPI = true;
          fileName = value.name;
          if (value.type.startsWith('image')) {
            fileType = "image";
          }
        }
      } else {
        // Add non-file data to values object, excluding the specific keys
        if (!excludedKeys.includes(key)) {
          values[key] = value;
          fields.push({ name: key });  // Add the field metadata for non-file fields
        }
      }
    });

    // Extract structured fields using the extractFields function
    let extractedFields = extractFields(formContent.fields);

    // Filter out the 'photo' field from the fields array
    extractedFields = extractedFields.filter(field => field.name !== 'photo');
    console.log(extractedFields);
    // Define the endpoint based on whether documentWebhookAPI is true
    const endpointUrl = `${REACT_APP_API_URL}${documentWebhookAPI ? "crm/documentwebhook/" : "webapp/updater"}`;

    // Append additional information if required by the document webhook
    if (documentWebhookAPI) {
      combinedFormData.append('label', fileType);
      combinedFormData.append('type', fileType);
      combinedFormData.append('name', fileName);
    }

    // Add action, user, and other form details
    combinedFormData.append('action', action);
    combinedFormData.append('user', user);
    combinedFormData.append('app_name', formData.get('app_name'));
    combinedFormData.append('model_name', formData.get('model_name'));
    combinedFormData.append('model_key_id', formData.get('model_key_id'));
    if (id) combinedFormData.append('model_id', id);

    // Append fields and values as structured data (fields is an array, values is an object)
    combinedFormData.append('fields', JSON.stringify(extractedFields));  // Add fields as JSON string
    combinedFormData.append('values', JSON.stringify(values));  // Add values as JSON string

    // CSRF token header
    const headers = {
      'X-CSRFToken': token,
    };

    setIsLoading(true);

    try {
      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers: headers,  // FormData will automatically handle the content type
        body: combinedFormData,  // Send the combined FormData with files + JSON data
        credentials: "include",
      });

      setIsLoading(false);

      if (!response.ok) {
        const errorData = await response.json();
        return { ok: false, status: response.status, data: errorData };
      }

      return { ok: true, data: await response.json() };
    } catch (error) {
      setIsLoading(false);
      console.error('Error during fetch operation:', error);
      return { ok: false, data: error };
    }
  }




  const handleSubmit = async (event) => {
    if(formType!=='router'){
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      console.log(formData);
      // Call the refactored function with FormData
      const result = await submitFormData(formData, context);

      //console.log(result);
      setActionData(result);
    }


    if (submitHandler){
      submitHandler();

    }

setFormSubmitted(true);
  };
  if (formType==='router'){
    return(
      <FormRouter   key={formKey} onSubmit={handleSubmit} className={className || ''} method={"POST"} action={action ? action : ""} >

      {renderFormContent()}
      </FormRouter>
    );
  }
  else{
  return (
    <form enctype={thereIsAFile ? "multipart/form-data" : undefined}  key={formKey} onSubmit={handleSubmit} className={className  || ''} method={"POST"} action={action ? action : ""} >

    {renderFormContent()}
    </form>

  );
  }
};

export default Form;
