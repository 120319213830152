import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { red } from '@mui/material/colors';
import AlertMessage from './AlertMessage';
import { useTranslationsContext } from '../../../store/context/translations-context';

const AlertsContainer = ({ issuesCount, steps }) => {
  const { requestTranslation } = useTranslationsContext(); // Access the translation context

  // State to store the translated text
  const [translatedText, setTranslatedText] = useState('');

  useEffect(() => {
    const fetchTranslations = async () => {
      const issuesToFix = await requestTranslation('issues-to-fix'); // Assuming 'issues-to-fix' is your translation key
      setTranslatedText(issuesToFix);
    };

    fetchTranslations();
  }, [requestTranslation]);

  return (
    <Accordion sx={{ bgcolor: red[50] }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ bgcolor: red[50], color: red[800] }}
      >
        <Typography className='flex gap-2 items-center' sx={{ color: red[800] }}>
          {issuesCount > 0 && (
            <>
              <span className='p4l-warning3 text-yellow-400 text-5xl'></span>

              {`${issuesCount} ${translatedText}`} {/* Use translation with dynamic count */}
            </>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {issuesCount > 0 &&
          steps.map((alert, index) => (
            <AlertMessage key={index} message={alert.message} action={alert.action} level="danger" />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AlertsContainer;
