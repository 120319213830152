import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { fetchHolidayEvents } from '../utils/cms';
import React, { useState, useEffect } from 'react';
import { useTranslationsContext } from '../store/context/translations-context';

export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState('pt'); // Default to Portuguese
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [translations, setTranslations] = useState({
    holidays: '',
  });

  useEffect(() => {
    // Dynamically import the locale based on the stored language
    const loadLanguage = async () => {
      const language = localStorage.getItem('language') || 'pt';
      if (language === 'pt') {
        const ptLocale = (await import('@fullcalendar/core/locales/pt')).default;
        setLocale(ptLocale);
      } else {
        setLocale(language);
      }
    };

    loadLanguage();

    const getHolidays = async () => {
      try {
        const holidayEvents = await fetchHolidayEvents();
        setEvents(holidayEvents);
      } catch (error) {
        console.error('Error fetching holiday events:', error);
      }
    };

    getHolidays();
  }, []);

  useEffect(() => {
    const loadTranslations = async () => {
      const holidays = await requestTranslation('holidays'); // Fetch translation for 'holidays'
      setTranslations({ holidays });
    };

    loadTranslations();
  }, [requestTranslation]);

  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  };

  const mobileHeaderToolbar = {
    right: 'prev,next today',
    center: 'title',
    left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  };

  return (
    <div className="sm:p-4">
      <div className="flex items-center mb-4">
        <div className="w-5 h-5 bg-[#10B981] mr-2"></div>
        <span className="text-sm">{translations.holidays}</span>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={window.innerWidth < 768 ? mobileHeaderToolbar : headerToolbar}
        initialView="dayGridMonth"
        events={events}
        eventDisplay="block" // Ensure all-day events are displayed properly
        locale={locale}
      />
    </div>
  );
}
