import React, { useState, useEffect } from 'react';
import { useTranslationsContext } from '../../store/context/translations-context';

const FinanceStatus = ({ status }) => {
    const { requestTranslation } = useTranslationsContext(); // Access the translation context

    const [translatedText, setTranslatedText] = useState({
        transFinancials: ''
    });

    useEffect(() => {
        const translateLabels = async () => {
            const transFinancials = await requestTranslation('financials');
            setTranslatedText({ transFinancials });
        };

        translateLabels();
    }, [requestTranslation]); // Run effect when translation function changes

    // Determine the background color based on status
    const bgColor = (() => {
        switch (status) {
            case 'GREEN':
                return 'bg-green-500'; // Assuming Tailwind CSS color
            case 'RED':
                return 'bg-red-500';
            case 'UNCHECKED':
            case 'NOT_FOUND':
                return 'bg-orange-500';
            default:
                return 'bg-gray-200'; // Default background
        }
    })();

    return (
        <div className={`flex items-center gap-2 font-medium text-gray-900 dark:text-white me-3`}>
            <span className={`flex w-2.5 h-2.5 rounded-full me-1.5 flex-shrink-0 ${bgColor}`}></span>
            {translatedText.transFinancials}
        </div>
    );
};

export default FinanceStatus;
