// ** React Imports
import React, { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Third Party Components
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import { Link } from 'react-router-dom'
// ** Keen Slider CSS Import
import 'keen-slider/keen-slider.min.css';

const Swiper = ({ direction = 'ltr', autoplay = 12000 }) => {
  // ** States
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(0)

  // ** Hook
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    rtl: direction === 'rtl',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created(slider) {
      setLoaded(true)
      setTotalSlides(slider.track.details.slides.length)
    },
    slides: {
      perView: 1.3, // Show a portion of the next slide
      spacing: 5,  // Add spacing between slides
    },
  })

  // Auto-switching effect
  useEffect(() => {
    if (instanceRef.current) {
      const timer = setInterval(() => {
        instanceRef.current.next()
      }, autoplay)
      return () => clearInterval(timer)
    }
  }, [instanceRef, autoplay])

  return (
    <Box className="flex flex-col gap-8">
    <Box className='navigation-wrapper w-full sm:w-1/2 mx-auto' sx={{ position: 'relative' }}>
      <Box ref={sliderRef} className='keen-slider rounded-lg'>
        <Link to={"https://docs.p4lpro.com/cards/dap"}>
          <Box className='keen-slider__slide'>
            <img
              src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-1.jpg'
              alt='swiper 1'
              style={{ width: '100%', objectFit: 'cover' }}  // Ensure images fit well inside
            />
          </Box>
        </Link>
        <Link to={"https://docs.p4lpro.com/cards/demo"}>
          <Box className='keen-slider__slide'>
            <img
              src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-2.jpg'
              alt='swiper 2'
              style={{ width: '100%', objectFit: 'cover' }}
            />
          </Box>
        </Link>
        <Box className='keen-slider__slide'>
          <img
            src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-3.jpg'
            alt='swiper 3'
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Box className='keen-slider__slide'>
          <img
            src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-4.jpg'
            alt='swiper 4'
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Box>
        <Box className='keen-slider__slide'>
          <img
            src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-5.jpg'
            alt='swiper 5'
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>

      {loaded && instanceRef.current && (
        <>
          {/* Arrows */}
          <IconButton
            className={clsx('arrow arrow-left')}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              zIndex: 10,
            }}
            onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
          >
            <span className="p4l p4l-circle-left"></span>
          </IconButton>

          <IconButton
            className={clsx('arrow arrow-right')}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              zIndex: 10,
            }}
            onClick={e => e.stopPropagation() || instanceRef.current?.next()}
          >
            <span className="p4l p4l-circle-right"></span>
          </IconButton>

          {/* Dots */}

        </>
      )}
    </Box>
              <Box
              className="dots w-4/5 sm:w-1/2 mx-auto"
              sx={{
                display: 'flex',
                justifyContent: 'center',


              }}
            >
              {[...Array(totalSlides)].map((_, idx) => (
                <Box
                  key={idx}
                  onClick={() => instanceRef.current?.moveToIdx(idx)}
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: currentSlide === idx ? '#000' : '#ddd',
                    margin: '0 5px',
                    cursor: 'pointer',
                  }}
                ></Box>
              ))}
            </Box>
            </Box>
  )
}

export default Swiper
